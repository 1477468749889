<template>
  <div class="userDetail">
    <!-- 修改姓名 -->
    <Dialog ref="dialog" :config="config">
      <div>
        <el-input v-model="value"></el-input>
      </div>
    </Dialog>
    <Dialog ref="dialog3" :config="config3">
      <div>
        <el-input v-model="value"></el-input>
      </div>
    </Dialog>
    <!-- 修改唛头-->
    <Dialog ref="dialog2" :config="config2">
      <div>
        <el-input v-model="value"></el-input>
      </div>
    </Dialog>
    <!-- 修改州 -->
    <Dialog ref="dialog5" :config="config5">
      <div>
        <el-select class="option" :placeholder="$fanyi('请选择地区')" v-model="value">
          <el-option :label="item" :value="item" v-for="(item, index) in $imdata.provincesList" :key="item + index">
          </el-option>
        </el-select>
      </div>
    </Dialog>
    <!-- 修改nif -->
    <Dialog ref="dialog6" :config="config4">
      <div>
        <el-input v-model="value"></el-input>
      </div>
    </Dialog>
    <!-- 修改手机号 -->
    <!-- <Dialog ref="dialogtel" :config="config6">
      <div>
        <el-input v-model="value" :placeholder="$fanyi('请输入新手机号')"></el-input>
      </div>
    </Dialog> -->
    <div class="Con">
      <nav>{{ $fanyi('基本信息') }}</nav>
      <div class="content">
        <div class="ConOpt">
          <label>{{ $fanyi('姓氏') }}：</label>
          <div class="userMessage">
            <input class="istrueInput" disabled v-model="userDatas.user_last_name" />
            <div class="gou" v-if="userDatas.user_last_name != null">✔</div>
            <button @click="changeUserName('last_name')">
              {{ $fanyi('修改') }}
            </button>
          </div>
        </div>
        <div class="ConOpt">
          <label>{{ $fanyi('名字') }}：</label>
          <div class="userMessage">
            <input v-model="userDatas.user_name" disabled />
            <div class="gou" v-if="userDatas.user_name != null">✔</div>
            <button @click="changeUserName('name')">{{ $fanyi('修改') }}</button>
          </div>
        </div>
        <div class="ConOpt">
          <label>{{ $fanyi('州') }}：</label>

          <div class="userMessage">
            <input v-model="userDatas.state" disabled />
            <div class="gou" v-if="userDatas.state != null">✔</div>
            <button @click="changeUserName('zhou')">
              {{ $fanyi('修改') }}
            </button>
          </div>
        </div>
        <div class="ConOpt">
          <label>{{ $fanyi('SSN(选填)') }}</label>
          <div class="userMessage">
            <input v-model="userDatas.user_id_card" disabled type='text' />

            <div class="gou" v-if="userDatas.user_id_card != null">✔</div>
            <button @click="changeUserName('NIF')">
              {{ $fanyi('修改') }}
            </button>
          </div>
        </div>
        <div class="ConOpt">
          <label>{{ $fanyi('电子邮箱') }}：</label>
          <div class="userMessage">
            <input v-model="userDatas.email" disabled />
            <div class="gou" v-if="userDatas.email != null">✔</div>
            <button @click="$fun.toPage('/user/changepassword?type=email')">
              {{ $fanyi('修改') }}
            </button>
          </div>
        </div>

        <div class="ConOpt">
          <label>{{ $fanyi('手机') }}：</label>
          <div class="userMessage">
            <input v-model="userDatas.mobile" disabled />
            <div class="gou" v-if="userDatas.mobile != null">✔</div>
            <button @click="$fun.routerToPage('/user/changepassword?type=mobile')">
              {{ $fanyi('修改') }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Dialog from '../../../../components/public/newDialog'
export default {
  data() {
    return {
      userDatas: {},
      value: '',// 修改的值
      mark: '',
      markList: [],
      config: {
        top: '35vh',
        width: '420px',
        title: this.$fanyi('修改姓氏'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      config2: {
        top: '35vh',
        width: '420px',
        title: this.$fanyi('修改唛头'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      config3: {
        top: '35vh',
        width: '420px',
        title: this.$fanyi('修改名字'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      config4: {
        top: '35vh',
        width: '420px',
        title: this.$fanyi('修改SSN'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      config5: {
        top: '35vh',
        width: '420px',
        title: this.$fanyi('修改州'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      config6: {
        top: '35vh',
        width: '420px',
        title: this.$fanyi('修改手机号'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
    }
  },
  components: { Dialog },
  computed: {},
  created() {
    this.getData()

  },

  methods: {
    // 获取个人信息数据
    getData() {
      this.$api.EuropegetUserInfo().then((res) => {
        if (res.code != 0) return this.message.error(res.msg)
        this.userDatas = res.data

        this.$store.commit('userData', res.data)
      })
    },
    // 修改唛头
    changeMark() {
      this.value = this.mark
      this.$refs.dialog2.open(
        '',

        () => {
          if (this.mark == '') {
            this.$api
              .userMarkAdd({
                mark: this.value,
                is_default: 1,
              })
              .then((res) => {

                if (res.code != 0) return

                this.getmark()
              })
          } else {
            this.$api
              .userMarkUpdate({
                id: this.markList[0].id,
                mark: this.value,
                is_default: 1,
              })
              .then((res) => {

                if (res.code != 0) return

                this.getmark()
              })
          }
        },
        () => { }
      )
    },
    // 获取唛头
    getmark() {
      this.$api.userMarkList().then((res) => {
        if (res.code != 0) return
        this.mark = res.data[0].mark
        this.markList = res.data
      })
    },

    // 修改用户信息
    changeUserName(type) {
      if (type == 'name') {
        this.value = this.userDatas.user_name
        this.$refs.dialog3.open(
          '',
          () => {
            this.$api
              .userUserNameSave({ user_name: this.value })
              .then((res) => {
                if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))

                this.getData()
              })
          },
          () => { }
        )
      } else if (type == 'last_name') {
        this.value = this.userDatas.user_last_name
        this.$refs.dialog.open(
          '',
          () => {
            this.$api
              .userUserLastNameSave({ user_last_name: this.value })
              .then((res) => {
                if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
                this.getData()
              })
          },
          () => { }
        )
      } else if (type == 'NIF') {
        this.value = this.userDatas.user_id_card
        this.$refs.dialog6.open(
          '',
          () => {
            let str = /^(\w){9}$/
            if (!str.test(this.value)) {
              this.$message.error(this.$fanyi('SSN输入错误'))
              return
            }
            this.$api
              .userUserIdCardSave({ user_id_card: this.value })
              .then((res) => {

                if (res.code != 0) return this.$message.error(res.msg)
                this.getData()
              })
          },
          () => { }
        )
      } else if (type == 'zhou') {
        this.value = this.userDatas.state

        this.$refs.dialog5.open(
          '',
          () => {
            this.$api.modifyzhou({ state: this.value }).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg)
              this.getData()
            })
          },
          () => { }
        )
      } else if (type == 'Cell phone') {
        this.value = this.userDatas.mobile
        this.$refs.dialogtel.open(
          '',
          () => {

            let str = /^(\w){10}$/
            if (!str.test(this.value)) {
              this.$message.error(this.$fanyi('请输入正确的手机号'))
              return
            }
            if (this.value == '') {
              this.$message.error(this.$fanyi('手机号不能为空'))
              return
            }
            this.$api
              .userMobileSave({ mobile: this.value })
              .then((res) => {

                if (res.code != 0) return this.$message.error(res.msg)
                this.$message({
                  message: this.$fanyi('手机号更改成功'),
                  type: 'success'
                });

                this.getData()
              })
          },
          () => { }
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../../../css/mixin.scss';

/deep/ .el-input__inner {
  /deep/ .el-input__inner {
    width: 360px;
    height: 48px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
  }

  padding: 0 10px;
  transition: 0.4s;
}

.option {
  width: 360px;
  height: 48px;
}

/deep/ .el-dialog__title {
  font-size: 18px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #222222;
}

/deep/ .el-dialog__close {
  font-size: 18px;
  font-weight: 600;
  color: #222222;
}

.userDetail {
  width: 1180px;
  padding-bottom: 50px;
  border-radius: 6px !important;
  border: 1px solid #e2e2e2;

  background-color: #fff;
  overflow: hidden;

  nav {
    padding-bottom: 38px;
    font-size: 18px;

    color: #000000;
    line-height: 24px;
  }

  .Con {
    padding: 30px 0 0 30px;
    background: #ffffff;

    .content {
      display: flex;
      flex-wrap: wrap;

    }

    .ConOpt {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      margin-right: 128px;

      .userMessage {
        position: relative;
        display: flex;
        align-items: center;
      }

      .gou {
        position: absolute;
        font-weight: bold;
        left: 327px;
        top: 13px;
        color: #ff730b;

        &.regCodegou {
          right: 222px;
        }
      }

      label {
        display: inline-block;
        text-align: left;
        font-size: 14px;
        color: #000000;
        line-height: 19px;
        margin-bottom: 10px;
      }

      input {
        width: 360px;
        height: 48px;
        background: #ffffff;
        border-radius: 6px;
        border: 2px solid #ff730b;
        padding: 0 10px;
        transition: 0.4s;
      }

      button {
        width: 30px;
        height: 19px;
        font-size: 14px;
        margin-left: 20px;
        color: $homePageSubjectColor;
        line-height: 19px;
        background-color: transparent;
        user-select: none;
      }
    }

    .el-icon-view {
      position: absolute;
      left: 380px;
      top: 5px;
    }

    .el-button {
      width: 120px;
      height: 40px;
      background: #ff730b;
      border-radius: 4px;
      border-radius: 4px;
      margin-top: 10px;
      margin-left: 135px;
      font-size: 16px;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
</style>
